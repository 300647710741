import Link from "@material-ui/core/Link";
import LaunchIcon from '@material-ui/icons/Launch';
import {makeStyles} from "@material-ui/styles";
import classNames from 'classnames'
import * as React from 'react';
import {memo} from 'react';

const useStyles = makeStyles({
  link: {
    textDecoration: 'none',
  },
  linkText: {
    display: "inline-block",
    whiteSpace: "nowrap"
  },
  icon: {
    width: '0.5em',
    paddingLeft: 2,
  },
});

const NfieldId = memo(({className, record = {}, source}) => {
    const classes = useStyles();
    const value = record[source];
    return (
      <Link className={classNames(className, classes.link)}
            title={value}
            target="_blank"
            href={`https://online-managereu.nfieldmr.com/surveys/${value}`}>
                <span className={classes.linkText}>{value}
                  <LaunchIcon className={classes.icon}/>
                </span>
      </Link>
    );
  }
)


export default NfieldId;
