import * as React from 'react';
import {Admin, Resource} from 'react-admin';
import {authProvider} from "./auth";
import {dataProvider} from './dataProvider';
import { theme } from "./theme";
import {NfieldProjectList} from "./lists/NfieldProjectList";

export function App() {
  return (
    <Admin
      theme={theme}
      authProvider={authProvider}
      dataProvider={dataProvider}
    >
      <Resource name="nfield-project" list={NfieldProjectList}/>
    </Admin>
  );
}
