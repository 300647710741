import React from "react";
import {Datagrid, List, Pagination, TextField, UrlField} from 'react-admin';
import NfieldId from "../components/NfieldId";

const PostPagination = props => <Pagination {...props} rowsPerPageOptions={[10, 25, 50, 100]}/>;

export const NfieldProjectList = props => (
  <List {...props} pagination={<PostPagination/>}>
    <Datagrid>
      <NfieldId label="Nfield ID" source="id"/>
      <TextField label="Name" source="name"/>
      <TextField label="Fieldwork status" source="fieldworkStatus"/>
      <TextField label="Nfieldgate ID" source="nfieldgateId"/>
      <TextField label="Nfieldgate server" source="nfieldgateInstance"/>
      <UrlField label="QRE builder link" source="qreProjectLink" target="_blank"/>
    </Datagrid>
  </List>
);
